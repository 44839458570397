<template>
  <div>
    <Pane />

    <a-card class="container">
      <div class="list">
        <div class="item" v-for="(item, index) in typeList" :key="item.value">
          <div class="title">
            <img :src="imageTitle" alt="" />
            {{ item.name }}
          </div>
          <UserSelector
            :index="index"
            :selectedList="item.selectedList"
            @setSelectedList="setSelectedList"
          />
        </div>
      </div>
      <div class="center">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" @click="submit" :loading="saveLoading"
            >保存</a-button
          >
        </a-space>
      </div>
    </a-card>
  </div>
</template>

<script>
import UserSelector from "./components/user-selector.vue";
import {  mapActions, mapGetters } from "vuex";
import { selectUser } from '@/api/bidding/evaluation'
export default {
  components: {
    UserSelector,
  },

  data() {
    return {
      imageTitle: require("@/assets/recruit/icon-hr.png"),
      typeList: [],
      id: '',
      saveLoading: false,
    };
  },

  computed: {
      ...mapGetters("setting", ["findDataDict"]),
    roleList() {
      return this.findDataDict("bid_jury_role");
    },
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    if (id && this.id !== id) {
      this.id = id;
      this.getList();
      console.log('this.rolelist', this.roleList)
      this.typeList = this.roleList.map(item => {
        return {
          name: item.name,
          value: item.value,
          selectedList: [],
        }
      })
    }

  },

  methods: {
    ...mapActions("employeeSelector", ["getList"]),

    setSelectedList({ index, selectedList }) {
      this.typeList.splice(index, 1, {
        ...this.typeList[index],
        selectedList,
      });
    },
    submit() {
      const list = [];
      this.typeList.forEach((item) => {
        item.selectedList.forEach(element => {
          list.push({
            itemId: this.id,
            juryRemarks: '',
            role: item.value,
            userId: element.userId,
            userName: element.name
          })
        });
      });
      if (list.length === 0) {
        this.$message.error("请选择人员！");
        return;
      }
      
      if (list.length % 2 === 0) {
        this.$message.error('评标委员人数必须为奇数！')
        return;
      }
      this.saveLoading = true;
      selectUser({
        juryList: list,
      }).then(() => {
        this.cancel();
      }).finally(() => {
        this.saveLoading = false;
      })
    },
    cancel() {
      this.$close(this.$route.path);
    },
  },
};
</script>

<style lang="less" scoped>

.center {
  margin-top: 80px;
  
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #f0f0f0;
  gap: 1px;

  .item {
    padding: 8px 12px;
    background-color: #fff;

    .title {
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 12px;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: bold;

      img {
        height: 16px;
        margin-right: 8px;
      }
    }
  }
}
</style>